import React from 'react'
import { EloIconProps } from '@elo-ui/components/icons/product-types/types'

export const EloTwintIcon: React.FC<EloIconProps> = ({ width = 153, height = 58 }) => (
  <svg xmlns='http://www.w3.org/2000/svg' height={height} viewBox='0 0 153 58' width={width}>
    <radialGradient id='a' cx='22.357%' cy='8.811%' gradientTransform='matrix(1 0 0 .91756 0 .007)' r='113.202%'>
      <stop offset='0' stop-color='#fc0' />
      <stop offset='.09157' stop-color='#ffc800' />
      <stop offset='.1739' stop-color='#ffbd00' />
      <stop offset='.2528' stop-color='#ffab00' />
      <stop offset='.3295' stop-color='#ff9100' />
      <stop offset='.4046' stop-color='#ff7000' />
      <stop offset='.4786' stop-color='#ff4700' />
      <stop offset='.5503' stop-color='#ff1800' />
      <stop offset='.5822' stop-color='#f00' />
      <stop offset='1' stop-color='#f00' />
    </radialGradient>
    <radialGradient id='b' cx='2.552%' cy='14.432%' gradientTransform='matrix(1 0 0 .68117 0 .046)' r='139.457%'>
      <stop offset='0' stop-color='#00b4e6' />
      <stop offset='.201' stop-color='#00b0e3' />
      <stop offset='.3898' stop-color='#01a5db' />
      <stop offset='.5737' stop-color='#0292cd' />
      <stop offset='.7546' stop-color='#0377ba' />
      <stop offset='.9316' stop-color='#0455a1' />
      <stop offset='1' stop-color='#054696' />
    </radialGradient>
    <g fill='none'>
      <path
        d='m150.90357 58h-148.78081678c-1.18229293 0-2.12275322-.9406858-2.12275322-2.1232623v-53.75347542c0-1.18257646.94046029-2.12326228 2.12275322-2.12326228h148.75394678c1.182293 0 2.1233.94068582 2.1233 2.12326228v53.75347542c.026324 1.1825765-.941007 2.1232623-2.09643 2.1232623z'
        fill='#000'
      />
      <path
        d='m48 39.3882699c0 .5589601-.4019746 1.2510059-.8843441 1.5171773l-17.2313118 9.8749613c-.4823695.2927887-1.2863187.2927887-1.7686882 0l-17.2313118-9.8749613c-.4823695-.2927886-.8843441-.9582172-.8843441-1.5171773v-19.7765398c0-.5589601.4019746-1.2510059.8843441-1.5171773l17.2313118-9.87496134c.4823695-.29278861 1.2863187-.29278861 1.7686882 0l17.2313118 9.87496134c.4823695.2927886.8843441.9582172.8843441 1.5171773z'
        fill='#fff'
      />
      <g fill='#fff' transform='translate(57 20)'>
        <path d='m85.839.684h-15.48v3.605h5.536v15.474h4.381v-15.474h5.563z' />
        <path d='m15.534.684h-15.48v3.605h5.563v15.474h4.381v-15.474h5.536z' />
        <path d='m60.334375.07894737c-4.864375 0-7.57875 3.05263158-7.57875 7.44736842v12.23684211h4.326875v-12.34210527c0-1.92105263 1.155625-3.39473684 3.305625-3.39473684 2.123125 0 3.27875 1.7368421 3.27875 3.39473684v12.34210527h4.326875v-12.23684211c0-4.39473684-2.795-7.44736842-7.659375-7.44736842z' />
        <path d='m43.968.684v19.079h4.353v-19.079z' />
        <path d='m29.294 8.263.161.842 4.058 10.658h1.774l5.536-19.079h-4.273l-2.661 10.027-.134 1.078-.215-1.078-3.548-10.027h-1.397l-3.521 10.027-.215 1.078-.161-1.078-2.634-10.027h-4.273l5.537 19.079h1.773l4.058-10.658z' />
      </g>
      <g transform='translate(14 20)'>
        <path
          d='m23.0029412 9.12820513-4.4205883 6.30769227-2.2764705-3.3846153 2.6205882-3.79487184c.4764706-.66666667 1.5352941-2.53846154.317647-5.07692308-.9794117-2.05128205-3.0970588-3.05128205-4.95-3.05128205-1.8529411 0-3.8911764.92307692-4.94999995 3.05128205-1.21764706 2.43589744-.15882353 4.35897436.29117647 5 0 0 1.45588238 2.07692312 2.67352938 3.82051282l1.9852941 2.7692308 2.9647059 4.3846154c.0264706.025641.5029412.7179487 1.3235294.7179487.7941177 0 1.2705883-.6923077 1.35-.7692308l6.9617647-9.97435897zm-8.7088236.15384615s-1.1647058-1.71794872-1.9058823-2.8974359c-.8205882-1.28205128.1058823-3.17948717 1.9058823-3.17948717 1.8264706 0 2.7264706 1.89743589 1.9058824 3.17948717-.7411765 1.20512821-1.9058824 2.8974359-1.9058824 2.8974359z'
          fill='url(#a)'
        />
        <path
          d='m10.0058824 15.2307692-4.36764711-5.92307689s-1.16470588-1.71794872-1.90588235-2.8974359c-.82058823-1.28205128.10588235-3.17948718 1.90588235-3.17948718.2382353 0 .45.02564103.63529412.07692308l1.53529412-2.71794872c-.71470588-.28205128-1.45588235-.43589744-2.17058824-.43589744-1.85294117 0-3.89117647.92307693-4.95 3.05128206-1.21764706 2.43589743-.15882352 4.35897435.29117648 5l7.62352941 10.94871799c.05294117.1025641.55588235.7948717 1.35.7948717.82058822 0 1.27058822-.6666666 1.35000002-.7692307l2.3029412-3.3846154-1.9852942-2.8205128z'
          fill='url(#b)'
        />
      </g>
    </g>
  </svg>
)
